
.backoffice_app_actions {
  >span,>a,>div {
    display:inline-block;
    padding: .2em 0;
    min-width:1.8em;
    text-align:center;
    border:solid 1px #ddd;
    cursor: pointer;
    &:hover {
      color: #fff;
      background-color: #da542c;
    }
  }
}

.backoffice_contact_actions {
  .bo_icon_button {
    display:inline-block;
    padding: .2em 0;
    min-width:1.8em;
    text-align:center;
    border:solid 1px #ddd;
    cursor: pointer;
    &:hover {
      color: #fff;
      background-color: #da542c;
    }
  }

  .contact_status_active {
    color: green;
  }
  .contact_status_disabled {
    color: red;
  }
}

.backoffice_request_actions {
  &_table {
    display: flex;
    >div {
      display:flex;
      align-items: center;
      &:not(:last-child) {
        margin-right: 2em;
      }
      >div {
        &:first-child {
          font-weight: bold;
          padding-right: .5em;
        }

        a.button.is-create {
          margin-right:1em;
        }
      }
    }
  }
}

.assign_contact {
  .minimal_button {
    margin-bottom: 1em;
  }
}

.custom_hosting_cell {
  &.hosting_cell_status_needs_check,
  &.hosting_cell_status_error,
  &.hosting_cell_status_cancelled {
    color:red;
  }

  &.hosting_cell_status_active {
    color:green;
  }

  &.hosting_cell_status_planned {
    color:blue;
  }
}